import React from "react";

function Services() {
    return (
        <section id="services" className="services">
            <h3>Services</h3>
            <div className="servicesContainer">
                <ul>
                    <li>Wedding</li>
                    <li>Highlights</li>
                    <li>Roller Set</li>
                </ul>
                <ul>
                    <li>Cut</li>
                    <li>Color</li>
                    <li>Style</li>
                </ul>
            </div>
        </section>
    )
}

export default Services;