import React from 'react';
import Home from './views/Home';
import './App.scss';

function App() {
    return(
        <div className="App">
            <Home />
        </div>
    );
}

export default App;
