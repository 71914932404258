import React from "react";

function Contact() {
    return(
        <section id="contact" className="contact">
            <h3>Contact</h3>
            <p>To book an appointment please call Maro at <a href="tel:858-442-0548" className="phone">(858)-442-0548</a></p>
        </section>
    )
}

export default Contact;