import React from "react";

function Footer() {
    return (
        <footer className="footer">
            <p>Hair by Maro &copy; Copyright {new Date().getFullYear()}</p>
        </footer>
    )
}

export default Footer